import React from "react";
import { Accordion, Card } from '@themesberg/react-bootstrap';


const ModifyCommentList = (props) => {

    
    const rowData = props.rowData;
    const obj = props.obj;

   // console.log(rowData);

    return (
        <>
            {obj.isError && <div className="alert alert-danger" role="alert">{obj.isError}</div>}
            {obj.isPending && <div>Loading...</div>}
            {obj.data && rowData &&

                <Accordion defaultActiveKey="0" className="mb-5">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Activity log</Accordion.Header>
                    <Accordion.Body>
                        {rowData.map((comment, index) =>{ 
                                return (
                                    <React.Fragment key={`comment-${index}`}>
                                        <Card className="mb-3"><Card.Body >
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="user d-flex flex-row align-items-center" style={{ paddingRight: "10px" }}>  
                                                    <span>
                                                        <small className="font-weight-bold text-primary p-1">{comment['submittedBy']}</small> 
                                                        <small className="font-weight-bold">
                                                            {comment['Comments'].split('\n').map((item, key) => { return <span key={key}>{item}<br/></span> })} 
                                                        </small>
                                                    </span>
                                                </div> 
                                                <small>{comment['createdAt']}</small>                                                
                                            </div>
                                            </Card.Body>  
                                        </Card>
                                    </React.Fragment>
                                )
                            })}
                    </Accordion.Body>
                </Accordion.Item>
                </Accordion>
                
            }
        </>
    )
}

export default ModifyCommentList;