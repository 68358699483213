import {validEmail} from "../../framework/utilities/utilities";
const coAutherRelationTypes = [3,6,7];

export default function fetchRelations(){
    
    // lets fetch the relations
    const tmp = [];
    const API_URL = process.env.REACT_APP_API_URL;
    fetch(API_URL + '/v1/web/relations/data/list', {
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem("token"),
        }
    }).then(response => response.json())
    .then(jsondata => {
        // converting relation types json to array format
        Object.keys(jsondata.relation_types).forEach(key => {

        //console.log(jsondata.relations[key]);

        // for co-authors we need to loop one more level
        if (coAutherRelationTypes.includes(Number(key))){
            const tmp_rels = [];  
            Object.keys(jsondata.relations[key]).forEach(key2 => {
                tmp_rels.push({
                    value: key2,
                    publication: jsondata.relations[key][key2]
                });
            });

            tmp.push({
                value: key,
                relation_type_name: jsondata.relation_types[key].name,
                relation_type_description: jsondata.relation_types[key].description,
                relations: tmp_rels
            });
            //console.log()

        }else{
            tmp.push({
                value: key,
                relation_type_name: jsondata.relation_types[key].name,
                relation_type_description: jsondata.relation_types[key].description,
                relations: jsondata.relations[key]
            });
        }
            
    });   
         
    }).catch((err) => {
        console.log("Login error: " + err);
        
    });
    return tmp;
}

export function validateForm(postedData, inputList, recId){

    //console.log(postedData);

    let errors = {};
    let formIsValid = true;
    
    if (Number(postedData.relationType) === 0){
        errors["relationType"] = "Please select the relation type.";
        formIsValid = false;
    }
    if (  (!coAutherRelationTypes.includes(Number(postedData.relationType))) && (Number(postedData.relationType !== 8)) && ( (Number(postedData.rUserId) === 0) || (postedData.rUserId === "") ) ) {
        errors["rUserId"] = "Please select the person name.";
        formIsValid = false;
    }else if ( (!coAutherRelationTypes.includes(Number(postedData.relationType))) && (Number(postedData.relationType !== 8)) && (postedData.rUserId !== "") && (postedData.rUserId === postedData.rUserName)){
        // check if the new user being added is an email address        
        if (!validEmail(postedData.rUserId)) {
            errors["rUserId"] = "You tried to link a person not in our system. Please type only the email address of the person you are trying to add in the Person name dropdown and click on 'Create email'.";
            formIsValid = false;
        }        
    }
    /*if ( (coAutherRelationTypes.includes(Number(postedData.relationType))) && (Number(postedData.relationType !== 8)) && (Number(postedData.publicationId) === 0)){
        errors["publicationId"] = "Please enter the publication name.";
        formIsValid = false;
    }*/
    if ( (Number(postedData.relationType === 3)) && (postedData.publicationName === "")){
        errors["publicationId"] = "Please enter the publication title.";
        formIsValid = false;
    }
    if ( ([6, 7].includes(Number(postedData.relationType))) && (postedData.publicationName === '')){
        errors["publicationId"] = "Please enter the award name.";
        formIsValid = false;
    }
    if ((Number(postedData.relationType === 8)) && ( (postedData.rInstitutionId === 0) || (postedData.rInstitutionId === '')) ){
        errors["rInstitutionId"] = "Please select the institution.";
        formIsValid = false;
    }
    if (( inputList.length === 0 ) && ([6, 7, 3].includes(Number(postedData.relationType))) ){
        if([6, 7].includes(Number(postedData.relationType)))
            errors["coAuthorDetails"] = "Please select Co-awardees.";
        else if(Number(postedData.relationType === 3))
            errors["coAuthorDetails"] = "Please select co-author.";

        formIsValid = false;
    }
    if ((postedData.startDateMand === 1) && ( (postedData.dateStartYear === "Invalid date") || (postedData.dateStartYear === "") || (postedData.dateStartYear === "null")  || (postedData.dateStartYear === null)  ) ){
        errors["startDate"] = "Please select the "+ postedData.startDateLabel.toLowerCase();
        formIsValid = false;
    }
    
    if ((postedData.endDateMand === 1) && ( (postedData.dateEndYear === "Invalid date") || (postedData.dateEndYear === "") || (postedData.dateEndYear === "null") || (postedData.dateEndYear === null) )){
        errors["endDate"] = "Please select the "+ postedData.endDateLabel.toLowerCase();
        formIsValid = false;
    }

    if ((postedData.dateEndYear) && (postedData.dateStartYear) && (postedData.dateStartYear > postedData.dateEndYear)){ 
        errors["endDate"] = "Please check the dates. End date should be greater than start date.";
        formIsValid = false;
    }

    if ((recId !== undefined) && (recId !== 0) && (postedData.sourceUserIsLoggedInUser === 1) && (postedData.sourceUserUpdateComment === "") ) {
        errors["sourceUserUpdateComment"] = "Please leave a comment noting what changes are made. You can also leave a comment as a response to other users.";
        formIsValid = false;
    }
    

    if (!formIsValid){

       // console.log(errors);

        return (
            <>
                <strong>Please check below fields:</strong><ul>
                {
                    Object.keys(errors).map(key => {
                        /*let myElement = document.getElementById('div-label-'+key);
                        if (myElement){
                            myElement.classList.add('is-invalid');
                        }*/                        
                        return(<li key={`error-${key}`}>{errors[key]}</li>)
                    })
                }
                </ul>
            </>
        ) 
    }else{
        return false;
    }
}


export function statusPill(status){
    return (
        <>
        {(status === 1) && <span className="badge rounded-pill bg-success">Validated</span>}
        {(status === 0) && <span className="badge rounded-pill bg-danger">Inactive</span>}
        {(status === 2) && <span className="badge rounded-pill bg-danger">Set for deletion</span>}
        {(status === 3) && <span className="badge rounded-pill bg-danger">Pending</span>}
        {(status === 4) && <span className="badge rounded-pill bg-danger">Disputed</span>}
        {(status === 'ACCEPTED') && <span className="badge rounded-pill bg-success">Accepted</span>}
        {(status === 'REJECTED') && <span className="badge rounded-pill bg-danger">Rejected</span>}
        {(status === 'PENDING') && <span className="badge rounded-pill bg-danger">Pending</span>}
        </>
    )
}
