import { useState, useEffect } from "react";
import ModuleBreadcrumb from '../../project/components/ModuleBreadcrumb';
import { Row, Col, Alert, Accordion,Spinner, Button, Form  } from '@themesberg/react-bootstrap';
import { Routes } from "../../routes";
//import { useParams } from 'react-router';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons";
//import Select from 'react-select';
import RelationListComponent from './ListComponent';
import Logout from "../../framework/utilities/Logout";

const ConflictList = () => {

    //const { conferencename} = useParams();
    const [generateList, setGenerateList] = useState(false);
    
    //const [conferenceOptions,setConferenceOptions] = useState([]);
    // const [conferenceId, setConferenceId] = useState('DEFAULT');
    const conferenceId = 'DEFAULT';
    // const [conferenceName, setConferenceName] = useState('Default');
    const conferenceName = 'Default';
    const [isLoadingConferences, setIsLoadingConferences] = useState(true);
    
    // default rules for all types stored in relations table
    const [defaultRules,setDefaultRules] = useState([]);
    const [selectedRules, setSelectedRules] = useState([]);

    // pending employment rules
   // const [defaultRulesPendingEmp,setDefaultRulesPendingEmp] = useState([]);
    const [selectedRulesPendingEmp, setSelectedRulesPendingEmp] = useState([]);

    // for institutions
    const [defaultRulesInst, setDefaultRulesInst] = useState([]);
    const [selectedRulesInst, setSelectedRulesInst] = useState([]);


    //const heading = "Conflicts for conference " + conferencename;
    const heading = "Conflicts";
    
    const fetchConflicts = () => {
        //console.log(selectedRules);
        setGenerateList(true);
    }

    useEffect(()=>{
        const tmp = [];
        const tmprules = [];
        tmp.push({
            value: conferenceId,
            label: conferenceName
        });
        const API_URL = process.env.REACT_APP_API_URL;
        fetch(API_URL + '/v1/web/conference/data/list/conferDrpList', {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem("token"),
            }
        }).then(response => response.json())
        .then(jsondata => {
           // console.log(jsondata);
            if (jsondata === 'Unauthorized'){
                Logout();
            }
           
            // for creating the conference dropdown
            Object.keys(jsondata.options).forEach(key => {
                tmp.push({
                    value: key,
                    label: jsondata.options[key]
                });
            });                
           // setConferenceOptions(tmp);
            
            // creating array for rules. This is used to display all the checkboxes
            Object.keys(jsondata.default_rules).forEach(key => {
                tmprules.push({
                    value: key,
                    label: jsondata.default_rules[key].rule_title,
                    relation_type_id: jsondata.default_rules[key].relation_type_id,
                    start_date_check: jsondata.default_rules[key].start_date_check,
                    end_date_check: jsondata.default_rules[key].end_date_check,
                    readonly_field: jsondata.default_rules[key].readonly_field,
                    operation_txt: jsondata.default_rules[key].operation_txt,
                    number_of_year: jsondata.default_rules[key].number_of_year
                });

               
            });   
           
            setDefaultRules(tmprules);
            setIsLoadingConferences(false);
           
        }).catch((err) => {
            //Logout();
            console.log("Login error: " + err);
            setIsLoadingConferences(false);
        });

        // getting institution conflcits
        fetch(API_URL + '/v1/web/institution/data/InstitutionConflictOption' , {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem("token"),
            }        
        })
        .then(response => response.json())
        .then(jsondata => {      
            if(jsondata.return_data)
                setDefaultRulesInst(jsondata.return_data);
    
        }).catch((err) => {
            console.log("Login error: " + err);
           
        });


    },[conferenceId,conferenceName,selectedRules]);

   

    return(
        <>
            <ModuleBreadcrumb links={[{ label:"List all relations", linkto: Routes.Relations.listall } , { label: heading } ]}  />
            
            <Row>
                <Col md={12} className="mb-3">
                    <Form.Group >

                        <Form.Label>Conflicts are generated from your relations subject to rules, which are defined for each individual conference.</Form.Label>
                        {/**<Form.Label>Conflicts are generated from your relations subject to rules, which are defined for each individual conference. To view a list of conflicts, please select a rule set. If not sure, use the DEFAULT rule set.</Form.Label>
                        
                        <Select name="conference" 
                                options={conferenceOptions} 
                                isLoading={isLoadingConferences}
                                defaultValue={{value: conferenceId, label: conferenceName}} onChange={event => {
                                    console.log(event);
                                    setConferenceId(event.value);
                                    setConferenceName(event.label);                                    
                                    }}   /> */}

                    </Form.Group>
                </Col>
            </Row>

            {isLoadingConferences ?
                <Alert variant="info" className="mt-4"><Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
                style={{marginRight:10}}
                />Please wait while we get rules...</Alert>

                :
                <>
                {defaultRules && 
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header><h5 className="mb-0">Conflict rules</h5></Accordion.Header>
                            <Accordion.Body>
                                <p>Below are the rules set by the conference chair:</p>                            
                                {defaultRules.map((row, index) => (
                                        <Form.Group key={`key-${index}-${row.value}`} className="mb-3" controlId="formBasicCheckbox">
                                            <Form.Check 
                                            type="checkbox"
                                            disabled={row.readonly_field}
                                            defaultChecked={row.readonly_field} 
                                            label={`${row.label}`} 
                                            onChange={(e)=>{
                                                // we reset the flag here so that the list can be generated again and component gets reloaded
                                                setGenerateList(false);
                                                // add rule to the list
                                                if (e.target.checked) {
                                                    setSelectedRules([
                                                        ...selectedRules,
                                                        {
                                                            value: row.value,
                                                            label: row.label,
                                                            relation_type_id: row.relation_type_id,
                                                            start_date_check: row.start_date_check,
                                                            end_date_check: row.end_date_check,
                                                            readonly_field: row.readonly_field,
                                                            operation_txt: row.operation_txt,
                                                            number_of_year: row.number_of_year
                                                        },
                                                      ]);
                                                }else{
                                                    // remove from list
                                                    setSelectedRules(
                                                        selectedRules.filter((rule) => rule.value !== row.value),
                                                    );
                                                }

                                            }}
                                        />
                                        </Form.Group>
                                    ))}

                                    {/** Pending employment rules */}
                                    <Form.Group className="mb-3">
                                        <Form.Check 
                                            type="checkbox"
                                            label={`Pending employment or graduate school application with an on-going discussion: 6 months from the <application date>`}
                                            onChange={(e)=>{
                                                setGenerateList(false);
                                                // add rule to the list
                                                if (e.target.checked) {
                                                    setSelectedRulesPendingEmp([
                                                        ...selectedRulesPendingEmp,
                                                        {
                                                            value: 1,
                                                            label: 'Pending employment or graduat...',
                                                            relation_type_id: 8,
                                                            start_date_check: 0,
                                                            end_date_check: 1,
                                                            readonly_field: 0,
                                                            operation_txt: 'WITHIN_X_MONTHS',
                                                            number_of_year: 0,
                                                            number_of_month: 6
                                                        },
                                                      ]);
                                                }else{
                                                    // remove from list
                                                    setSelectedRulesPendingEmp(
                                                        selectedRulesPendingEmp.filter((rule) => rule.value !== 1),
                                                    );
                                                }
                                            }}
                                            />
                                    </Form.Group>

                                    {/** Institution rules */}
                                    { ( defaultRulesInst.length > 0 ) && 
                                        defaultRulesInst.map((row, index) => ( 
                                            <Form.Group key={`key-${index}-${row.value}`} className="mb-3" controlId="formBasicCheckbox">
                                                <Form.Check 
                                                    type="checkbox"
                                                    label={`${row.label}`} 
                                                    onChange={(e)=>{ 
                                                        setGenerateList(false);

                                                        if (e.target.checked) {
                                                            setSelectedRulesInst([
                                                                ...selectedRulesInst,
                                                                {
                                                                    value               : row.value,
                                                                    label               : row.label,
                                                                    years               : row.years,
                                                                    type_of_position    : row.type_of_position
                                                                },
                                                            ]);
                                                        }else{
                                                            // remove from list
                                                            setSelectedRulesInst(
                                                                selectedRulesInst.filter((rule) => rule.value !== row.value),
                                                            );
                                                        }
                                                    }}
                                                />
                                            </Form.Group>
                                        ))
                                    }

                                <Button variant="primary" className="mt-3 animate-hover btn btn-primary btn-primary-small-rounded" onClick={() => fetchConflicts() }  >
                                    <FontAwesomeIcon icon={faList} className="animate-left-3 me-3 ms-2" />Generate conflict list 
                                </Button>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                }
                </>
            }
            

            { (generateList) &&
                <>
                    <RelationListComponent conflictRules={defaultRules} generateList={generateList} selectedRules={selectedRules} selectedRulesPendingEmp={selectedRulesPendingEmp} selectedRulesInst={selectedRulesInst} pageName={`conflictListing`} />
                </>
            }

            
        </>
    );
}

export default ConflictList